import { ReactNode } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

type Props = { icon: ReactNode; label: string } & BoxProps;
export const IconTitle = ({ icon, label, ...rest }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      {...rest}
    >
      {icon}
      <Typography
        variant="subtitle1"
        component="p"
        ml={1}
      >
        {label}
      </Typography>
    </Box>
  );
};
