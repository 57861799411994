import router from 'next/router';
import { useTranslation } from 'react-i18next';
import Loading from '@/components/common/loading/Loading';
import { Role } from '@/components/dynamic-nav/types';
import { RequestTileProps } from '@/components/questionnaire/Home/types';
import { GET_EXISTING_PROJECT_REQUESTS, GET_MY_REQUESTING_AUTHORITIES } from '@/graphql/disclosure/queries';
import { GetExistingProjectRequestsQuery, MyRequestsQuery } from '@/lib/discloser/__generated__/graphql';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { OrganisationTypeIdService } from '@/types/organisation.type.id.service';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { ChevronRight, GroupAddOutlined } from '@mui/icons-material';
import { Avatar, AvatarGroup, Button, Card, Grid, Stack, Typography } from '@mui/material';

export const RequestTile = ({ organisationTypeId }: RequestTileProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });
  const { role } = useSettings();
  const isOrganisationTypeCompany = organisationTypeId === OrganisationTypeIdService.COMPANY();

  const { data, loading } = useQuery<MyRequestsQuery>(GET_MY_REQUESTING_AUTHORITIES, {
    variables: { take: 25, skip: 0 },
    client: disclosureClient,
    fetchPolicy: 'network-only',
    skip: role !== Role.DISCLOSER,
    onError: onApolloError,
  });

  const { data: existingProjectsData, loading: isLoadingExistingProjectsData } =
    useQuery<GetExistingProjectRequestsQuery>(GET_EXISTING_PROJECT_REQUESTS, {
      client: disclosureClient,
      onError: onApolloError,
      fetchPolicy: 'network-only',
      skip: !organisationTypeId || role !== Role.DISCLOSER || isOrganisationTypeCompany,
    });

  if (loading || isLoadingExistingProjectsData) return <Loading />;

  const handleRedirect = () => {
    router.push({
      pathname: Routes.MyRequests,
    });
  };

  const noReqOrgs = isOrganisationTypeCompany
    ? data?.myRequests.totalRequesters
    : existingProjectsData?.getExistingProjectRequests.totalRequests;

  if (!noReqOrgs || noReqOrgs === 0) return null;

  return (
    <Grid
      item
      md={6}
    >
      <Card
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: theme => theme.palette.common.white,
        }}
      >
        <Stack gap={1}>
          <Stack
            direction="row"
            gap={1}
          >
            <GroupAddOutlined />
            <Typography
              variant="subtitle1"
              component="p"
            >
              {t('requestTile.title', { noReqOrgs })}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              maxWidth={'217px'}
            >
              {t('requestTile.description', { noReqOrgs })}
            </Typography>
            <AvatarGroup
              total={noReqOrgs}
              sx={{
                ...(noReqOrgs > 5 && {
                  '.MuiAvatarGroup-avatar:first-of-type': {
                    width: 'auto',
                    paddingX: 1,
                    borderRadius: '100px',
                  },
                }),
              }}
            >
              {isOrganisationTypeCompany
                ? data?.myRequests?.requests?.map((req, idx) => (
                    <Avatar
                      key={`${req?.requestingAuthority?.organisationId}_${idx}`}
                      alt={req?.requestingAuthority?.orgName?.toString()}
                      src={req?.requestingAuthority?.logoUrl?.toString()}
                    />
                  ))
                : existingProjectsData?.getExistingProjectRequests?.projectInstanceOrganisations?.map((req, idx) => (
                    <Avatar
                      key={`${req?.projectInstance.project?.projectId}_${idx}`}
                      alt={req?.projectInstance.project?.authorityOrganisation?.orgName?.toString()}
                      src={req?.projectInstance.project?.logoUrl?.toString()}
                    />
                  ))}
            </AvatarGroup>
          </Stack>
        </Stack>
        <Stack direction="row-reverse">
          <Button
            color="secondary"
            variant="text"
            startIcon={<ChevronRight />}
            sx={{ width: 'fit-content' }}
            onClick={handleRedirect}
          >
            {t('requestTile.ctaLabel')}
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
};
