import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useCallback } from 'react';
import { openLinkInNewTab } from '@/utils/url-helper';
import { commonConfig } from '@config';
import { OndemandVideoOutlined } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';

export const LatestWebinar = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.supportTile' });
  const theme = useTheme();

  const redirectToLatestWebinar = useCallback(() => {
    openLinkInNewTab(commonConfig.eventsAndWebinarsLink);
  }, []);

  return (
    <Stack
      style={{ width: '100%', height: '100%', position: 'relative', cursor: 'pointer' }}
      onClick={redirectToLatestWebinar}
    >
      <Image
        src="/images/webinar-bg.webp"
        layout="fill"
        alt=""
        priority
      />
      <Stack
        position="absolute"
        p={2}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          gap={1}
        >
          <OndemandVideoOutlined sx={{ color: theme.palette.primary.contrastText }} />
          <Typography
            variant="subtitle1"
            component="p"
            color="primary.contrastText"
          >
            {t('latestWebinarTitle')}
          </Typography>
        </Stack>
        <Typography
          variant="h6"
          component="p"
          color="primary.contrastText"
        >
          {t('latestWebinarSubtitle')}
        </Typography>
      </Stack>
    </Stack>
  );
};
