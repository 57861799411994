export enum InvoiceStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  SKIPPED = 'skipped',
  PARTIAL = 'partial',
  FREE = 'free',
  FAILED = 'failed',
}

export enum PaymentType {
  CARD = 'card',
  INVOICE = 'invoice',
}
