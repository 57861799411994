// utils
import { addDays, differenceInDays, isBetween } from '@/utils/date';
// mui
import { Box, styled } from '@mui/material';
// components
import CurrentDayFlag from './CurrentDayFlag';
import Dot from './Dot';

type PhaseTimelineProps = {
  startDate: Date;
  dueDate: Date;
  currentDate: Date;
  isFullVersion?: boolean;
  smallScreen?: boolean;
};

const StyledPhaseTimeline = styled(Box)(() => ({
  flexGrow: 1,
  minWidth: '15px',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,  minmax(0, 1fr))',
  alignItems: 'center',
  justifyContent: 'center',
}));

const fullVersionMaxDotLength = 78;
const shortVersionMaxDotLength = 22;

export default function PhaseTimeline({
  startDate,
  dueDate,
  currentDate,
  isFullVersion,
  smallScreen,
}: PhaseTimelineProps) {
  const maxDotLength = isFullVersion ? fullVersionMaxDotLength : shortVersionMaxDotLength;
  const totalPhaseLengthInDays = differenceInDays(startDate, dueDate);
  const dotPhaseSeperator = 7;
  let dotRangeDays = 1;
  let totalDots = totalPhaseLengthInDays;

  if (totalPhaseLengthInDays > maxDotLength) {
    dotRangeDays = totalPhaseLengthInDays / maxDotLength;
    totalDots = maxDotLength;
  }
  return (
    <StyledPhaseTimeline>
      {totalDots > 0 &&
        Array.from(Array(totalDots).keys()).map(idx => {
          const currentDay = isBetween(
            currentDate,
            addDays(startDate, idx * dotRangeDays),
            addDays(startDate, (idx + 1) * dotRangeDays),
          );
          return (
            <Dot
              key={idx}
              small={idx % dotPhaseSeperator !== 0}
              zIndex={currentDay ? 1 : undefined}
              sx={{
                '&:after': {
                  backgroundColor: theme =>
                    idx % dotPhaseSeperator === 0 ? theme.palette.text.secondary : theme.palette.text.disabled,
                },
              }}
            >
              {currentDay ? (
                <CurrentDayFlag
                  daysRemaining={smallScreen ? undefined : differenceInDays(new Date(), dueDate)}
                  side={idx > totalDots / 2 ? 'left' : 'right'}
                />
              ) : null}
            </Dot>
          );
        })}
    </StyledPhaseTimeline>
  );
}
