import {
  GetActiveDisclosureCycleQuery,
  GetQuestionnaireSetupByOrgIdQuery,
  OrganisationModel,
} from '@/lib/discloser/__generated__/graphql';

export interface UserStep {
  name: string;
  completed: boolean | undefined;
  key: string;
}

export interface OnBoardingStepperProps {
  organisation: OrganisationModel;
  questionnaireSubmitted: boolean;
  organisationTypeId: string | null;
  phase: string | undefined;
  hasInvoice?: boolean | null;
  isFeePaid: boolean;
  opensMonth: string | undefined;
  opensYear: string | undefined;
  confirmIntention: boolean | null;
}

export interface OnBoardingTitleProps {
  phase?: string;
  hasInvoice?: boolean | null;
  organisation: OrganisationModel;
  organisationTypeId: string | null;
  confirmIntention: boolean | null;
  activeCycleData: GetActiveDisclosureCycleQuery | undefined;
  questionnaireSetupData: GetQuestionnaireSetupByOrgIdQuery | undefined;
}

export type ToDoListProps = {
  phaseName: string;
  organisationTypeId: string | null;
  confirmIntention: boolean | null;
};

export type RequestTileProps = {
  organisationTypeId: string | null;
};

export enum OrgTypeName {
  CITY = 'city',
}

export enum C40Requested {
  CityC40 = 'city-C40',
}
