// i18next
import { useTranslation } from 'react-i18next';
// mui
import { Box, styled } from '@mui/material';
import { red } from '@mui/material/colors';
// components
import Dot from './Dot';

type Props = {
  daysRemaining: number;
  side: 'left' | 'right';
};

const Bar = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  height: '10px',
  position: 'absolute',
}));

function CurrentDayFlagTooltip({ daysRemaining, side }: Props) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire' });

  return (
    <Box
      sx={theme => ({
        position: 'absolute',
        paddingBlock: theme.spacing(0.5),
        paddingInline: theme.spacing(1),
        fontSize: '10px',
        lineHeight: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: red[100],
        color: theme.palette.primary.main,
        whiteSpace: 'nowrap',
        borderRadius: theme.spacing(0.5),
        ...(side === 'right' ? { left: 16 + 8.5 } : { right: 16 + 8.5 }),
        '&:before': {
          content: '""',
          display: 'block',
          width: 0,
          height: 0,
          position: 'absolute',
          borderTop: '6px solid transparent',
          borderBottom: '6px solid transparent',
          top: '5px',
          ...(side === 'right'
            ? {
                borderRightStyle: 'solid',
                borderRightWidth: '6px',
                borderRightColor: red[100],
                left: '-6px',
              }
            : {
                borderLeftStyle: 'solid',
                borderLeftWidth: '6px',
                borderLeftColor: red[100],
                right: '-6px',
              }),
        },
      })}
    >
      {t(daysRemaining === 1 ? 'timeline.remainingDays_one' : 'timeline.remainingDays_other', { count: daysRemaining })}
    </Box>
  );
}

export default function CurrentDayFlag({ daysRemaining, side }: { daysRemaining?: number; side: 'left' | 'right' }) {
  return (
    <Box
      sx={{
        top: -6,
        left: -6,
        position: 'absolute',
      }}
    >
      <Box
        sx={theme => ({
          minWidth: theme.spacing(2),
          minHeight: theme.spacing(2),
          maxWidth: theme.spacing(2),
          maxHeight: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Bar sx={{ top: -10 }} />
        <Dot
          sx={{
            '&:after': {
              backgroundColor: theme => theme.palette.common.white,
            },
          }}
        />
        <Bar sx={{ bottom: -10 }} />
        {daysRemaining && (
          <CurrentDayFlagTooltip
            daysRemaining={daysRemaining}
            side={side}
          />
        )}
      </Box>
    </Box>
  );
}
