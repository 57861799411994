import numeral from 'numeral';

type Value = string | number | null | undefined;

export function Percent(number: Value, format = '0%') {
  return number !== null && number !== undefined ? numeral(Number(number) / 100).format(format) : '';
}

export function formatNumber(number: Value, format = '00') {
  return numeral(number).format(format);
}

export function formatCurrency(amount: Value, currencyCode: string | undefined, locale = 'en-US') {
  if (amount === null || amount === undefined) {
    return '';
  }

  if (!currencyCode) {
    return new Intl.NumberFormat('en-US').format(Number(amount));
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(Number(amount));
}
