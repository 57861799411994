import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { openLinkInNewTab } from '@/utils/url-helper';
import { commonConfig } from '@config';
import { LiveHelpOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

export const LatestGuidance = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.latestGuidanceTile' });

  const openGuidancePage = useCallback(() => {
    openLinkInNewTab(commonConfig.additionalGuidanceLink);
  }, []);

  return (
    <Stack
      display="flex"
      height="100%"
      bgcolor="background.default"
      p={2}
      gap={2}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        gap={1}
      >
        <LiveHelpOutlined color="primary" />
        <Typography
          variant="subtitle1"
          component="p"
          color="primary.main"
        >
          {t('title')}
        </Typography>
      </Stack>
      <Typography
        variant="h6"
        component="p"
        color="primary.main"
        sx={{ cursor: 'pointer' }}
        onClick={openGuidancePage}
      >
        {t('ctaLabel')}
      </Typography>
    </Stack>
  );
};
