import { useCallback, useEffect, useState } from 'react';
import { ProgressPoints } from '@/types/response';
import axiosInstance from '@/utils/axios-client';

async function fetchStatistics() {
  try {
    const response = await axiosInstance.get('/questionnaire/portal/statistics');
    return response.data;
  } catch (error) {
    throw new Error(`Unexpected error occured while fetching statistics: ${error}`);
  }
}

interface Statistics extends ProgressPoints {
  totalSectionNumbers: number;
  completedSectionNumbers: number;
  unanswered: number;
  progress: number;
}

export function useStatistics() {
  const [
    statisticsData,
    setStatisticsData,
  ] = useState<Statistics>();
  const [
    loading,
    setLoading,
  ] = useState(true);

  const fetchAllstatistics = useCallback(async () => {
    try {
      const statistics = await fetchStatistics();
      setStatisticsData({
        started: statistics.inProgress,
        progress: statistics.inProgress,
        completedSectionNumbers: statistics.completedSections,
        conditional: 0,
        ...statistics,
      });
    } catch (error) {
      console.error('error fetching statistics:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllstatistics();
  }, [
    fetchAllstatistics,
  ]);

  return { loading, data: statisticsData, fetchAllstatistics };
}
