import { HelpCenterCategories, HelpCenterSubCategories } from '@/types/response';
import { commonConfig } from '@config';

export const getWithParametersConstructedHelpCenterURL = (
  categoryName: HelpCenterCategories,
  subCategoryName: HelpCenterSubCategories,
  subject?: string,
) => {
  return (
    (process.env.NEXT_PUBLIC_HELP_CENTER_LINK as string) +
    `/en-US/support/create-case/` +
    '?' +
    new URLSearchParams({
      subject: subject ?? commonConfig.CRMHelpCenterCategoryIDs.subjects[subCategoryName],
      category: commonConfig.CRMHelpCenterCategoryIDs.categories[categoryName],
      subCategory: commonConfig.CRMHelpCenterCategoryIDs.subCategories[subCategoryName],
    }).toString()
  );
};

export const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener noreferrer');
};
