// i18n
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { openLinkInNewTab } from '@/utils/url-helper';
// routes
import { commonConfig } from '@config';
// MUI
import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';

export const HelpCenterTile = () => {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home.helpCenterTile' });

  const handleRedirectToHelpCenter = useCallback(() => {
    openLinkInNewTab(commonConfig.helpCentreLink);
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      height="100%"
      bgcolor="background.default"
      p={2}
    >
      <Box>
        <Typography variant="subtitle1">{t('title')}</Typography>
        <Typography
          variant="body2"
          mt={1}
        >
          {t('desc')}
        </Typography>
      </Box>
      <Box alignSelf="flex-end">
        <Button
          variant="text"
          LinkComponent={Link}
          onClick={handleRedirectToHelpCenter}
        >
          {t('linkLabel')}
          <ChevronRight fontSize="small" />
        </Button>
      </Box>
    </Stack>
  );
};
