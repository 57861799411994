import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { InvoiceStatus } from '@/components/payment/types';
import { DynamicQuestionnaireCtaButton } from '@/components/questionnaire/Home/DynamicQuestionnaireCtaButton';
import { OnBoardingTitleProps } from '@/components/questionnaire/Home/types';
import { GET_NEXT_DISCLOSURE_CYCLE_FOR_DISCLOSER, GET_ORGANISATION_INVOICE } from '@/graphql/disclosure/queries';
import {
  GetNextDisclosureCycleForDiscloserQuery,
  GetOrganisationInvoiceQuery,
  GetOrganisationInvoiceQueryVariables,
} from '@/lib/discloser/__generated__/graphql';
import { Role } from '@/types/user';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { Chip, Stack, Typography } from '@mui/material';
import img from '../../../public/images/OnboardingTileBg.png';
import OnboardingStepper from './OnboardingStepper';

export default function OnboardingTile({
  phase,
  hasInvoice,
  organisation,
  organisationTypeId,
  confirmIntention,
  activeCycleData,
  questionnaireSetupData,
}: OnBoardingTitleProps) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home.onboardingTile' });
  const { data: session } = useSession();
  const [
    opensMonth,
    setOpensMonth,
  ] = useState('');
  const [
    opensYear,
    setOpensYear,
  ] = useState('');
  const isSubLead = session?.user?.roles.includes(Role.DISCLOSER_SUBMISSION_LEAD);
  useQuery<GetNextDisclosureCycleForDiscloserQuery>(GET_NEXT_DISCLOSURE_CYCLE_FOR_DISCLOSER, {
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
    skip: activeCycleData?.getActiveDisclosureCycle !== null,
    onCompleted: data => {
      const opensMonthDate = new Date(data.getNextDisclosureCycle?.startDate).toLocaleString('default', {
        month: 'long',
      });
      const opensYearDate = String(new Date(data.getNextDisclosureCycle?.startDate).getFullYear());
      setOpensMonth(opensMonthDate);
      setOpensYear(opensYearDate);
    },
  });
  const { data: organisationInvoice } = useQuery<GetOrganisationInvoiceQuery, GetOrganisationInvoiceQueryVariables>(
    GET_ORGANISATION_INVOICE,
    {
      variables: {
        status: [
          InvoiceStatus.PAID,
          InvoiceStatus.UNPAID,
          InvoiceStatus.FREE,
        ],
        disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
      },
      client: disclosureClient,
      onError: onApolloError,
      fetchPolicy: 'network-only',
      skip: !isSubLead || !activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
    },
  );

  return (
    <Stack
      component="div"
      sx={{
        width: '100%',
        backgroundImage: `url(${img.src})`,
        color: theme => theme.palette.primary.contrastText,
        backgroundSize: 'cover',
      }}
    >
      <Stack
        component={'div'}
        sx={{
          width: '100%',
          background: 'rgba(0, 93, 180, 0.8)',
          p: 3,
        }}
        spacing={1}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            variant="h4"
            component={'p'}
          >
            {t('title')}
          </Typography>
          {phase === 'request' && (
            <Chip
              color="info"
              label={t('disclosureCycleClosed.chipText', { openMonth: opensMonth, openYear: opensYear })}
              sx={{ color: theme => theme.palette.primary.contrastText, borderRadius: '20px' }}
            />
          )}
          {phase === 'track' && <DynamicQuestionnaireCtaButton />}
        </Stack>
        {phase === 'request' && (
          <Stack>
            <Typography
              variant="body1"
              component={'p'}
            >
              {t('disclosureCycleClosed.description1', { openMonth: opensMonth })}
            </Typography>
            <Typography
              variant="body1"
              component={'p'}
            >
              {t('disclosureCycleClosed.description2')}
            </Typography>
          </Stack>
        )}
        {phase === 'track' && (
          <Stack>
            <Typography
              variant="body1"
              component={'p'}
            >
              {t('disclosureCycleOpen.description1')}
            </Typography>
            <Typography
              variant="body1"
              component={'p'}
            >
              {t('disclosureCycleOpen.description2')}
            </Typography>
          </Stack>
        )}
        <OnboardingStepper
          hasInvoice={hasInvoice}
          organisation={organisation}
          questionnaireSubmitted={questionnaireSetupData?.getQuestionnaireSetupByOrgId?.isSubmitted ?? false}
          organisationTypeId={organisationTypeId}
          opensMonth={opensMonth}
          opensYear={opensYear}
          phase={phase}
          confirmIntention={confirmIntention}
          isFeePaid={!!organisationInvoice?.getOrganisationInvoice}
        />
      </Stack>
    </Stack>
  );
}
