// utils
import { GetServerSideProps } from 'next';
// components
import { Home } from '@/components/questionnaire/Home/Home';
// layouts
import MainLayout from '@/layouts/main/MainLayout';
import { getServerSideTranslations } from '@/utils/i18n';

const Page = () => {
  return <Home />;
};

Page.getLayout = (page: React.ReactElement) => (
  <MainLayout
    className="main-layout"
    sx={{ padding: 0 }}
  >
    {page}
  </MainLayout>
);

export default Page;

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  try {
    return {
      props: {
        ...(await getServerSideTranslations(locale, [
          'questionnaire',
        ])),
      },
    };
  } catch {
    return { notFound: true };
  }
};
