// i18n
import { useTranslation } from 'next-i18next';
// next
import Link from 'next/link';
// mui
import { useMetadata } from '@/hooks/response/useMetadata';
// store
// types
import { QuestionnaireInstanceStatus } from '@/types/response';
// utils
import { Routes } from '@/utils/urls';
import { ChevronRight } from '@mui/icons-material';
import { Button, Grid, GridProps, Stack } from '@mui/material';

export const DynamicQuestionnaireCtaButton = ({ ...rest }: GridProps) => {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.progressButton' });

  // TODO: [nathan ormond]: refactor after breaking up metadata hook in BE (User Story 57851)
  const { status } = useMetadata();

  return (
    <Grid {...rest}>
      <Stack>
        {status && (
          <Button
            variant="contained"
            LinkComponent={Link}
            href={Routes.Questionnaire.Root}
          >
            {status === QuestionnaireInstanceStatus.InProgress
              ? t('continue')
              : status === QuestionnaireInstanceStatus.Submitted
                ? t('view')
                : status === QuestionnaireInstanceStatus.Amendments
                  ? t('edit')
                  : t('start')}
            <ChevronRight fontSize="small" />
          </Button>
        )}
      </Stack>
    </Grid>
  );
};
