// i18n
// components
import { useTranslation } from 'react-i18next';
// utils
import { formatDate, isAfter } from '@/utils/date';
// mui
import { Box, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import PhaseTimeline from './PhaseTimeline';

type PhaseProps = {
  expand: boolean;
  icon?: React.ReactNode;
  isFullVersion?: boolean;
  label?: string;
  startDate: Date;
  dueDate: Date;
};

const StyledPhase = styled(Box, {
  shouldForwardProp: prop => prop !== 'expand',
})<{ expand?: boolean }>(({ theme, expand }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: expand ? 1 : 0,
  alignItems: 'center',
  columnGap: theme.spacing(2),
  paddingInline: theme.spacing(1.5),
  paddingBlock: theme.spacing(1),
  lineHeight: 1,
  fontSize: 13,
  fontWeight: theme.typography.fontWeightBold,
  whiteSpace: 'nowrap',
}));

const PhaseLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: theme.spacing(0.5),
}));

export default function Phase({ expand, isFullVersion = true, icon, label, startDate, dueDate }: PhaseProps) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.timeline' });
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const currentDate = new Date();

  return (
    <StyledPhase expand={expand}>
      {isFullVersion && !smallScreen && (
        <PhaseLabel>
          {icon}
          {expand ? label : formatDate(dueDate, 'MMM d')}
        </PhaseLabel>
      )}
      {expand ? (
        <>
          <PhaseTimeline
            smallScreen={smallScreen}
            isFullVersion={isFullVersion}
            startDate={startDate}
            dueDate={dueDate}
            currentDate={currentDate}
          />
          <Typography
            variant="inherit"
            color={'primary.main'}
          >
            {isAfter(currentDate, dueDate) ? t('closed') : t('closingDate', { date: formatDate(dueDate, 'dd MMM yy') })}
          </Typography>
        </>
      ) : null}
    </StyledPhase>
  );
}
