import { DiscloserListModel } from '@/lib/discloser/__generated__/graphql';

export type TOption = { value: string; label: string };

export type PartialSearchParams = {
  keyword: string;
  country: string;
};

export type ListFilter = 'all' | 'active' | 'pending' | 'closed';

export interface ManageSlice {
  statusFilter: ListFilter;
  discloserLists: DiscloserListModel[];
  filteredDiscloserLists: DiscloserListModel[];
  actions: {
    setStatusFilter: (filter: ListFilter) => void;
    setDiscloserLists: (disclosers: DiscloserListModel[]) => void;
    setFilteredDiscloserLists: (disclosers: DiscloserListModel[]) => void;
  };
}

export interface AuthorityHubSlice {
  responseStatusFilter: string;
  actions: {
    setResponseStatusFilter: (status: string) => void;
  };
}

export enum OnboardingSteps {
  CONFIRM_YOUR_LEAD = 'confirmLead',
  MANAGE_YOUR_REQUESTS = 'manageRequests',
  QUESTIONNAIRE_SETUP = 'questionnaireSetup',
  CHOOSE_FEE = 'chooseFee',
  CONFIRM_YOUR_PARTICIPATION = 'confirmParticipation',
}

export enum PhasesEnum {
  REQUEST = 'request',
  TRACK = 'track',
  ANALYSE = 'analyse',
}
