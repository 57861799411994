import { ResourceKey } from 'i18next';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { GET_PHASES_INFORMATION } from '@/graphql/disclosure/queries';
import { GetPhasesInformationQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { PhasesEnum } from '@/types/discloser';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import {
  AvTimerOutlined,
  ConfirmationNumberOutlined,
  FactCheckOutlined,
  GroupAddOutlined,
  QueryStatsOutlined,
  TrackChangesOutlined,
} from '@mui/icons-material';
import { Alert, Box, Skeleton, Stack } from '@mui/material';
import Phase from './Phase';

type Props = { isDiscloser?: boolean };
export const PhaseInformation = ({ isDiscloser = false }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.phaseInfo' });
  const { authorityTypeId } = useStore();
  const { data, loading } = useQuery<GetPhasesInformationQuery>(GET_PHASES_INFORMATION, {
    client: disclosureClient,
    variables: { authorityTypeId },
    fetchPolicy: 'network-only',
    onError: onApolloError,
  });

  if (loading) return <Skeleton aria-label={t('loading')} />;

  if (!data) return <Alert severity="error">{t('noData')}</Alert>;

  if (
    !data.getPhasesInformation?.phaseName ||
    !data.getPhasesInformation?.startDate ||
    !data.getPhasesInformation?.endDate
  ) {
    return null;
  }

  const { name, icon, step, noOfPhases } = getPhaseData(data.getPhasesInformation?.phaseName as Phase, isDiscloser);

  return (
    <Stack
      gap={2}
      direction="row"
    >
      <PhaseItem
        label={t(name)}
        icon={icon}
      />
      <Stack
        bgcolor="background.default"
        alignSelf="stretch"
        width="100%"
        p={0.5}
      >
        <Phase
          expand={true}
          startDate={data.getPhasesInformation?.startDate}
          dueDate={data.getPhasesInformation?.endDate}
        />
      </Stack>
      <PhaseItem label={t('phaseSteps', { step, noOfPhases })} />
    </Stack>
  );
};

type PhaseItemProps = { label: string; icon?: ReactNode };
const PhaseItem = ({ label, icon }: PhaseItemProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      alignSelf="stretch"
      p={1.5}
      gap={1}
      lineHeight={1}
      fontSize={13}
      fontWeight={theme => theme.typography.fontWeightBold}
      sx={{
        color: 'text.primary',
        backgroundColor: 'background.default',
        textWrap: 'nowrap',
      }}
    >
      {icon}
      {label}
    </Box>
  );
};

export type Phase = PhasesEnum;

type PhaseData = { name: ResourceKey; step: number; noOfPhases: number; icon: ReactNode };
const getPhaseData = (phase: Phase, isDiscloser: boolean): PhaseData => {
  if (isDiscloser) {
    switch (phase) {
      case PhasesEnum.REQUEST: {
        return {
          name: 'discloserRequest',
          step: 1,
          noOfPhases: 3,
          icon: <TrackChangesOutlined fontSize="small" />,
        };
      }
      case PhasesEnum.TRACK: {
        return {
          name: 'discloserTrack',
          step: 2,
          noOfPhases: 3,
          icon: <GroupAddOutlined fontSize="small" />,
        };
      }
      case PhasesEnum.ANALYSE: {
        return {
          name: 'discloserAnalyse',
          step: 3,
          noOfPhases: 3,
          icon: <QueryStatsOutlined fontSize="small" />,
        };
      }
    }
  } else {
    switch (phase) {
      case PhasesEnum.REQUEST: {
        return {
          name: 'authorityRequest',
          step: 1,
          noOfPhases: 3,
          icon: <FactCheckOutlined fontSize="small" />,
        };
      }
      case PhasesEnum.TRACK: {
        return {
          name: 'authorityTrack',
          step: 2,
          noOfPhases: 3,
          icon: <ConfirmationNumberOutlined fontSize="small" />,
        };
      }
      case PhasesEnum.ANALYSE: {
        return {
          name: 'authorityAnalyse',
          step: 3,
          noOfPhases: 3,
          icon: <AvTimerOutlined fontSize="small" />,
        };
      }
    }
  }
};
