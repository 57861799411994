// mui
import { Box, BoxProps, alpha, styled } from '@mui/material';

const CardRendererWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  boxSizing: 'border-box',
  padding: '16px',
  background: theme.palette.common.white,
  border: `2px solid ${alpha(theme.palette.secondary.main, 0.08)}`,
}));

type Props = {
  children: React.ReactNode;
} & BoxProps;

export default function CardRenderer({ children, ...rest }: Props) {
  return <CardRendererWrapper {...rest}>{children}</CardRendererWrapper>;
}
