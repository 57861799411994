import { useEffect, useMemo } from 'react';
import { useStatistics } from '@/graphql/response/queries/statistics';
import { useOverallProgress, useSectionCompletenessProgress } from '@/store/response';

export const useProgressMetrics = () => {
  const overallProgress = useOverallProgress();
  const sectionCompletenessProgress = useSectionCompletenessProgress();
  const { loading, data: statisticsData, fetchAllstatistics } = useStatistics();

  useEffect(() => {
    if (overallProgress.total === 0) {
      fetchAllstatistics();
    }
  }, [
    overallProgress.total,
    fetchAllstatistics,
  ]);

  const metrics = useMemo(() => {
    if (overallProgress.total === 0 && statisticsData) {
      return statisticsData;
    }

    const { answered, reviewed, skipped, started, conditional, total: totalQuestions } = overallProgress;
    const { completedSectionNumbers, totalSectionNumbers } = sectionCompletenessProgress;

    const total = totalQuestions - conditional;
    const progress = reviewed + answered + skipped;
    const unanswered = total - progress - started;

    return {
      answered,
      reviewed,
      skipped,
      started,
      conditional,
      totalQuestions,
      completedSectionNumbers,
      totalSectionNumbers,
      total,
      progress,
      unanswered,
    };
  }, [
    JSON.stringify(overallProgress),
    JSON.stringify(sectionCompletenessProgress),
    JSON.stringify(statisticsData),
  ]);

  return { loading, ...metrics };
};
