import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { SELECT_PRODUCT_ITEM } from '@/graphql/disclosure/mutations';
import { GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER } from '@/graphql/disclosure/queries';
import {
  GetActiveDisclosureCycleQuery,
  ProductModel,
  SelectProductMutation,
  SelectProductMutationVariables,
} from '@/lib/discloser/__generated__/graphql';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { extractBulletPoints } from '@/utils/extractBulletPoints';
import { Routes } from '@/utils/urls';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

type Props = { data: ProductModel; setIsFreeProductSelected: (value: boolean) => void };

export const ProductCard = ({ data, setIsFreeProductSelected }: Props) => {
  const { data: disclosureCycleData, loading: getActiveDisclosureCycleLoading } =
    useQuery<GetActiveDisclosureCycleQuery>(GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER, {
      client: disclosureClient,
      onError: onApolloError,
    });

  const { t } = useTranslation('common', { keyPrefix: 'common.productCard' });
  const router = useRouter();

  const { name, description, isoCurrencyCode, totalAmount, productListItemId } = data;

  const productItemDescriptions = description ?? '';

  const [
    selectProduct,
    { loading },
  ] = useMutation<SelectProductMutation, SelectProductMutationVariables>(SELECT_PRODUCT_ITEM, {
    client: disclosureClient,
    onError: onApolloError,
    variables: {
      productListItemId,
      disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
    },
  });

  const selectProductItem = async () => {
    if (totalAmount === 0) {
      setIsFreeProductSelected(true);
      await selectProduct({
        variables: {
          productListItemId,
          disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
        },
      });
    } else {
      await selectProduct({
        variables: {
          productListItemId,
          disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
        },
      });

      await router.push({
        pathname: Routes.Payment,
      });
    }
  };

  return (
    <Box
      border="2px solid"
      borderRadius="4px"
      padding="14px"
      bgcolor="background.default"
      sx={{
        border: '1px solid grey',
        transition: theme => theme.palette.background.default,
        '&:hover': {
          borderColor: theme => theme.palette.primary.main,
        },
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack sx={{ flexGrow: 1 }}>
        <Typography
          variant="h5"
          component="h1"
          mb={2}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          mb={2}
        >
          <div dangerouslySetInnerHTML={{ __html: productItemDescriptions }} />
        </Typography>
      </Stack>

      <Box
        sx={{
          background: theme => theme.palette.background.paper,
          py: 2,
          px: 2,
        }}
      >
        <Typography
          variant="h4"
          display="inline"
        >
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: isoCurrencyCode }).format(totalAmount)}
        </Typography>
        <Typography
          variant="body2"
          display="inline"
        >
          {t('perYear')}
        </Typography>
      </Box>

      <Button
        disabled={getActiveDisclosureCycleLoading || loading}
        variant="contained"
        sx={{
          marginTop: 2,
        }}
        onClick={selectProductItem}
        fullWidth
      >
        {t('selectPlan')}
      </Button>
    </Box>
  );
};
