// mui
import { Box, styled } from '@mui/material';

const Dot = styled(Box, { shouldForwardProp: prop => prop !== 'small' })<{
  small?: boolean;
  zIndex?: number;
}>(({ theme, small = false, zIndex }) => {
  const dotSize = small ? theme.spacing(0.25) : theme.spacing(0.5);
  return {
    position: 'relative',
    borderRadius: '50%',
    minWidth: theme.spacing(0.5),
    minHeight: theme.spacing(0.5),
    maxWidth: theme.spacing(0.5),
    maxHeight: theme.spacing(0.5),
    padding: small ? 1 : 0,
    zIndex,
    '&:after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.text.disabled,
      borderRadius: '50%',
      minWidth: dotSize,
      minHeight: dotSize,
      maxWidth: dotSize,
      maxHeight: dotSize,
    },
  };
});

export default Dot;
