// react
import { useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import router from 'next/router';
import { useEffect, useState } from 'react';
// utils
import { Trans, useTranslation } from 'react-i18next';
// components
import { PhaseInformation } from '@/components/common/Timeline/PhaseInformation';
import Loading from '@/components/common/loading/Loading';
import { AccountManagement } from '@/components/home/Authority/HomeTiles/AccountManagement';
import { LatestGuidance } from '@/components/home/Authority/HomeTiles/LatestGuidance';
import { LatestWebinar } from '@/components/home/Authority/HomeTiles/LatestWebinar';
import { ProductList } from '@/components/home/Authority/Products/ProductList';
import { DataAndInsightsTile } from '@/components/home/DataAndInsightsTile/DataAndInsightsTile';
import OnboardingTile from '@/components/home/OnboardingTile/OnboardingTile';
import { RequestTile } from '@/components/home/RequestTile/RequestTile';
import { InvoiceStatus } from '@/components/payment/types';
import { HelpCenterTile } from '@/components/questionnaire/Home/HelpCenterTile';
import StatusInfoBar from '@/components/questionnaire/Home/StatusInfoBar';
import ToDoList from '@/components/questionnaire/Home/ToDoList';
// types, routes, queries
import {
  GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER,
  GET_MY_ORG_COUNTRY,
  GET_MY_ORG_DETAILS,
  GET_MY_REQUESTING_AUTHORITIES_TOTAL_REQUESTERS,
  GET_ORGANISATION_ACCOUNT_MANAGER,
  GET_ORGANISATION_INVOICE,
  GET_ORG_RESPONSE_BY_CYCLE_AND_QUESTIONNAIRE,
  GET_PHASES_INFORMATION,
  GET_QUESTIONNAIRE_SETUP_BY_ORG_ID,
} from '@/graphql/disclosure/queries';
import {
  GetActiveDisclosureCycleQuery,
  GetMyOrganisationDetailsQuery,
  GetOrgResponseByCycleQuery,
  GetOrganisationAccountManagerQuery,
  GetOrganisationInvoiceQuery,
  GetOrganisationInvoiceQueryVariables,
  GetPhasesInformationQuery,
  GetQuestionnaireSetupByOrgIdQuery,
  MyRequestsTotalRequestersQuery,
  OrganisationModel,
} from '@/lib/discloser/__generated__/graphql';
import { ResponseStatusEnum } from '@/types/response';
import { Role } from '@/types/user';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
// MUI
import { Alert, AlertTitle, Button, Grid, Link, Typography } from '@mui/material';

export const Home = () => {
  const { t } = useTranslation([
    'questionnaire',
  ]);

  const { data: session } = useSession();

  const searchParams = useSearchParams();
  const invoiceSubmitStatus = searchParams.get('invoicesubmitstatus');
  const paymentSubmitStatus = searchParams.get('paymentstatus');
  const paymentStatus = searchParams.get('paymentstatus');
  const viewMode = searchParams.get('viewmode');

  const { data: disclosureCycleData, loading: getActiveDisclosureCycleLoading } =
    useQuery<GetActiveDisclosureCycleQuery>(GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER, {
      client: disclosureClient,
      onError: onApolloError,
      fetchPolicy: 'network-only',
    });

  const { data: accountManager, loading: loadingAccountManager } = useQuery<GetOrganisationAccountManagerQuery>(
    GET_ORGANISATION_ACCOUNT_MANAGER,
    {
      client: disclosureClient,
      onError: onApolloError,
      fetchPolicy: 'network-only',
    },
  );

  const accData = accountManager?.getOrganisationAccountManager;

  const [
    isSelectionSkipped,
    setIsSelectionSkipped,
  ] = useState(false);

  const [
    isFreeProductSelected,
    setIsFreeProductSelected,
  ] = useState(false);

  const [
    invoiceSubmitStatusAlertOpen,
    setInvoiceSubmitStatusAlertOpen,
  ] = useState(!!invoiceSubmitStatus);

  const [
    paymentSubmitStatusAlertOpen,
    setPaymentSubmitStatusAlertOpen,
  ] = useState(!!paymentSubmitStatus);

  const isSubLead = session?.user?.roles.includes(Role.DISCLOSER_SUBMISSION_LEAD);

  const onDetailsClick = () => router.push(Routes.Organisation.Details);

  const { data, loading } = useQuery<GetMyOrganisationDetailsQuery>(GET_MY_ORG_DETAILS, {
    client: disclosureClient,
    onError: onApolloError,
    fetchPolicy: 'network-only',
  });

  const isEligibleToPay = data?.getMyOrganisationDetails?.isEligibleToPay ?? false;

  const { data: myTotalRequestCount } = useQuery<MyRequestsTotalRequestersQuery>(
    GET_MY_REQUESTING_AUTHORITIES_TOTAL_REQUESTERS,
    {
      client: disclosureClient,
      skip: !isEligibleToPay,
      fetchPolicy: 'network-only',
      onError: onApolloError,
    },
  );

  const totalRequestCount = myTotalRequestCount?.myRequests?.totalRequesters ?? 0;

  const { data: phaseData, loading: isLoadingPhaseData } = useQuery<GetPhasesInformationQuery>(GET_PHASES_INFORMATION, {
    client: disclosureClient,
    variables: { authorityTypeId: '' },
    fetchPolicy: 'network-only',
    onError: onApolloError,
  });

  const { data: organisationInvoice } = useQuery<GetOrganisationInvoiceQuery, GetOrganisationInvoiceQueryVariables>(
    GET_ORGANISATION_INVOICE,
    {
      variables: {
        status: [
          InvoiceStatus.PAID,
          InvoiceStatus.UNPAID,
          InvoiceStatus.FREE,
        ],
        disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId as string,
      },
      client: disclosureClient,
      fetchPolicy: 'network-only',
      onError: onApolloError,
      skip:
        !isSubLead ||
        !isEligibleToPay ||
        !disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId ||
        totalRequestCount === 0,
    },
  );
  const [
    paymentReminderAlert,
    setPaymentReminderAlert,
  ] = useState(false);

  useEffect(() => {
    setPaymentReminderAlert(
      !invoiceSubmitStatusAlertOpen && organisationInvoice?.getOrganisationInvoice?.status === InvoiceStatus.UNPAID,
    );
  }, [
    invoiceSubmitStatusAlertOpen,
    organisationInvoice?.getOrganisationInvoice?.status,
  ]);

  const { data: confirmIntentionData } = useQuery<GetOrgResponseByCycleQuery>(
    GET_ORG_RESPONSE_BY_CYCLE_AND_QUESTIONNAIRE,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      variables: {
        disclosureCycleId: disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      },
      onError: onApolloError,
      skip: !disclosureCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
    },
  );

  const { data: activeCycleData, loading: activeCycleLoading } = useQuery<GetActiveDisclosureCycleQuery>(
    GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
    },
  );

  const { data: questionnaireSetupData, loading: questionnaireSetupLoading } =
    useQuery<GetQuestionnaireSetupByOrgIdQuery>(GET_QUESTIONNAIRE_SETUP_BY_ORG_ID, {
      client: disclosureClient,
      skip: !activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      variables: {
        disclosureCycleId: activeCycleData?.getActiveDisclosureCycle?.disclosureCycleId,
      },
      onError: onApolloError,
      fetchPolicy: 'network-only',
    });

  const ONBOARDING_PHASES = [
    'request',
    'track',
  ];

  const { data: orgCountryData, loading: orgCountryLoading } = useQuery<GetMyOrganisationDetailsQuery>(
    GET_MY_ORG_COUNTRY,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
    },
  );
  const creditCardAccepted = orgCountryData?.getMyOrganisationDetails?.OrgCountry?.creditCardAccepted === true;

  let showProducts = false;

  if (
    isSubLead &&
    !isFreeProductSelected &&
    totalRequestCount > 0 &&
    isEligibleToPay &&
    (!isSelectionSkipped || viewMode === Routes.Products.Root.query.viewmode) &&
    !(
      organisationInvoice?.getOrganisationInvoice?.status === InvoiceStatus.UNPAID ||
      organisationInvoice?.getOrganisationInvoice?.status === InvoiceStatus.PAID ||
      organisationInvoice?.getOrganisationInvoice?.status === InvoiceStatus.FREE
    )
  ) {
    showProducts = true;
  }

  if (
    loading ||
    isLoadingPhaseData ||
    getActiveDisclosureCycleLoading ||
    loadingAccountManager ||
    orgCountryLoading ||
    activeCycleLoading ||
    questionnaireSetupLoading
  ) {
    return <Loading isContained />;
  }

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={3}
      data-testid="discloser-home-container"
    >
      <Grid
        item
        xs={12}
      >
        {(invoiceSubmitStatus?.toLowerCase() === 'success' ||
          invoiceSubmitStatus?.toLowerCase() === 'failed' ||
          paymentStatus?.toLowerCase() === 'transactionsuccessful') &&
          (invoiceSubmitStatusAlertOpen || paymentSubmitStatusAlertOpen) && (
            <Alert
              onClose={() => {
                setInvoiceSubmitStatusAlertOpen(false);
                setPaymentSubmitStatusAlertOpen(false);
              }}
              severity={
                invoiceSubmitStatus?.toLowerCase() === 'success' ||
                paymentStatus?.toLowerCase() === 'transactionsuccessful'
                  ? 'success'
                  : 'error'
              }
              sx={{ whiteSpace: 'pre-line' }}
              className="invoice-alert"
            >
              <>
                <AlertTitle>
                  {invoiceSubmitStatus?.toLowerCase() == 'success'
                    ? t('alerts.invoiceSubmitStatus.success.title')
                    : invoiceSubmitStatus?.toLowerCase() == 'failed'
                      ? t('alerts.invoiceSubmitStatus.error.title')
                      : paymentStatus?.toLowerCase() == 'transactionsuccessful'
                        ? t('alerts.paymentStatus.title.success')
                        : ''}
                </AlertTitle>
                {invoiceSubmitStatus?.toLowerCase() == 'success'
                  ? t('alerts.invoiceSubmitStatus.success.description')
                  : invoiceSubmitStatus?.toLowerCase() == 'failed'
                    ? t('alerts.invoiceSubmitStatus.error.description')
                    : paymentStatus?.toLowerCase() == 'transactionsuccessful'
                      ? t('alerts.paymentStatus.description.success')
                      : ''}
              </>
            </Alert>
          )}
      </Grid>
      <Grid
        item
        md={12}
        sx={{ display: 'flex' }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{ pr: 1 }}
        >
          {data?.getMyOrganisationDetails?.orgName}
        </Typography>
        <Button
          data-testid="discloser-show-org-detail-btn"
          variant="text"
          onClick={onDetailsClick}
        >
          {t('questionnaire.home.buttonLabels.showOrgDetails')}
        </Button>
      </Grid>
      <Grid
        item
        md={12}
      >
        {paymentReminderAlert && (
          <Alert
            onClose={() => {
              setPaymentReminderAlert(false);
            }}
            severity="warning"
            sx={{ whiteSpace: 'pre-line' }}
            className="invoice-alert"
          >
            <AlertTitle>{t('questionnaire.paymentReminder.title')}</AlertTitle>
            {creditCardAccepted ? (
              <Trans
                i18nKey="questionnaire.paymentReminder.allowedPayByCardDescription"
                ns="questionnaire"
                components={[
                  <Link
                    href={Routes.Payment}
                    key="linkComponent"
                    color="inherit"
                    underline="always"
                    sx={{ cursor: 'pointer' }}
                    aria-label="payment-link"
                  />,
                ]}
              />
            ) : (
              t('questionnaire.paymentReminder.notAllowedPayByCardDescription')
            )}
          </Alert>
        )}
      </Grid>
      <Grid
        item
        md={12}
      >
        <PhaseInformation isDiscloser />
      </Grid>
      {showProducts && (
        <Grid
          item
          md={12}
        >
          <ProductList
            setIsSelectionSkipped={setIsSelectionSkipped}
            setIsFreeProductSelected={setIsFreeProductSelected}
          />
        </Grid>
      )}
      {!showProducts && (
        <Grid
          item
          md={12}
        >
          {(questionnaireSetupData?.getQuestionnaireSetupByOrgId?.isSubmitted &&
            confirmIntentionData?.getOrgResponseByCycle?.responseStatus !== ResponseStatusEnum.NO_RESPONSE &&
            confirmIntentionData?.getOrgResponseByCycle?.responseStatus !== ResponseStatusEnum.REQUEST_NOT_SENT) ||
          !ONBOARDING_PHASES.includes(phaseData?.getPhasesInformation?.phaseName ?? '') ? (
            <StatusInfoBar
              phase={phaseData?.getPhasesInformation?.phaseName}
              responseStatus={confirmIntentionData?.getOrgResponseByCycle?.responseStatus}
            />
          ) : (
            <OnboardingTile
              hasInvoice={!!organisationInvoice?.getOrganisationInvoice}
              phase={phaseData?.getPhasesInformation?.phaseName}
              organisation={data?.getMyOrganisationDetails as OrganisationModel}
              organisationTypeId={data?.getMyOrganisationDetails?.organisationType?.organisationTypeId ?? null}
              confirmIntention={confirmIntentionData?.getOrgResponseByCycle?.confirmIntention ?? null}
              activeCycleData={activeCycleData}
              questionnaireSetupData={questionnaireSetupData}
            />
          )}
        </Grid>
      )}

      {!showProducts && (
        <RequestTile
          organisationTypeId={data?.getMyOrganisationDetails?.organisationType?.organisationTypeId ?? null}
        />
      )}

      {!showProducts && !!phaseData?.getPhasesInformation?.phaseName && (
        <Grid
          item
          md={6}
        >
          <ToDoList
            phaseName={phaseData?.getPhasesInformation?.phaseName}
            organisationTypeId={data?.getMyOrganisationDetails?.organisationType?.organisationTypeId ?? null}
            confirmIntention={confirmIntentionData?.getOrgResponseByCycle?.confirmIntention ?? null}
          />
        </Grid>
      )}
      {!showProducts && (
        <Grid
          item
          md={6}
        >
          <DataAndInsightsTile />
        </Grid>
      )}

      {!showProducts && (
        <Grid
          container
          item
          md={6}
          columnSpacing={2}
        >
          <Grid
            container
            item
            md={6}
            rowSpacing={2}
          >
            <Grid
              item
              md={12}
            >
              <HelpCenterTile />
            </Grid>
            <Grid
              item
              md={12}
            >
              <LatestGuidance />
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
          >
            <LatestWebinar />
          </Grid>
        </Grid>
      )}
      {!showProducts && accData && (
        <Grid
          item
          md={6}
        >
          <AccountManagement
            name={`${accData.firstName} ${accData.lastName}`}
            email={accData.emailAddress}
          />
        </Grid>
      )}
    </Grid>
  );
};
