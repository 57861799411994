// router
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { OpenInNewOutlined } from '@mui/icons-material';
// MUI
import { Link, ListItem, ListItemButton } from '@mui/material';

type Props = {
  title?: string | ResourceKey;
  link?: string;
  onClick?: () => void;
  newTab?: boolean;
};

export default function ToDoListItem({ title, link, onClick, newTab }: Props) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home.toDoList' });

  return (
    <ListItem sx={{ display: 'list-item' }}>
      <ListItemButton
        sx={{
          display: 'flex',
          'align-items': 'center',
          padding: 0,
          '&:hover': {
            background: 'none',
          },
          '&:active': {
            background: 'none',
          },
        }}
      >
        <Link
          onClick={onClick}
          color="secondary"
          underline="always"
          href={link}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: '#3C5568',
              background: 'none',
            },
          }}
        >
          {t(title)}
        </Link>
        {newTab && link && (
          <OpenInNewOutlined
            fontSize="small"
            sx={{ ml: 0.5 }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
}
