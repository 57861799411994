import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { C40Requested, OnBoardingStepperProps, UserStep } from '@/components/questionnaire/Home/types';
import { GET_MY_REQUESTING_AUTHORITIES } from '@/graphql/disclosure/queries';
import { MyRequestsQuery } from '@/lib/discloser/__generated__/graphql';
import { useSettings } from '@/providers/SettingsProvider/SettingsProvider';
import { OnboardingSteps, PhasesEnum } from '@/types/discloser';
import { OrganisationTypeIdService } from '@/types/organisation.type.id.service';
import { ReducedRole } from '@/types/user';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { CheckCircle, ChevronRight, CircleOutlined } from '@mui/icons-material';
import { Stack, StepConnector, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export default function OnboardingStepper({
  organisation,
  questionnaireSubmitted,
  organisationTypeId,
  phase,
  hasInvoice,
  isFeePaid,
  opensMonth,
  opensYear,
  confirmIntention,
}: OnBoardingStepperProps) {
  const router = useRouter();
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home' });
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useSettings();
  const userType = role as unknown as ReducedRole;
  const { data } = useQuery<MyRequestsQuery>(GET_MY_REQUESTING_AUTHORITIES, {
    fetchPolicy: 'network-only',
    client: disclosureClient,
    onError: onApolloError,
  });
  const name =
    organisation?.organisationType?.organisationTypeId === OrganisationTypeIdService.CITY() &&
    organisation.isC40Requested
      ? C40Requested.CityC40
      : organisation?.organisationType?.name.toLowerCase().replaceAll(' ', '') ?? '';
  const translateSections = `onboardingTile.steps.${name}`;
  const stepsDict: { [key: string]: string } = t(`${translateSections}`, {
    returnObjects: true,
    defaultValue: '',
  });
  const isCompleted = (step: string) => {
    if (phase === PhasesEnum.REQUEST) {
      return false;
    }

    switch (step) {
      case OnboardingSteps.CONFIRM_YOUR_LEAD: {
        return organisation.submissionLead !== null;
      }
      case OnboardingSteps.CONFIRM_YOUR_PARTICIPATION: {
        return typeof confirmIntention === 'boolean';
      }
      case OnboardingSteps.MANAGE_YOUR_REQUESTS: {
        return data?.myRequests.requests && data?.myRequests.requests?.length > 0;
      }
      case OnboardingSteps.QUESTIONNAIRE_SETUP: {
        return questionnaireSubmitted;
      }
      case OnboardingSteps.CHOOSE_FEE: {
        return isFeePaid;
      }

      default: {
        return false;
      }
    }
  };

  const isHidden = (step: string) => {
    switch (step) {
      case OnboardingSteps.MANAGE_YOUR_REQUESTS: {
        return !(phase === PhasesEnum.REQUEST && data?.myRequests && data?.myRequests?.totalRequesters > 0);
      }

      case OnboardingSteps.CONFIRM_YOUR_PARTICIPATION: {
        if (userType !== ReducedRole.DISCLOSER_PREFIX) {
          return false;
        }
        break;
      }

      default: {
        return false;
      }
    }
  };

  const steps: UserStep[] = Object.keys(stepsDict)
    .map(step => {
      return {
        key: step,
        name: stepsDict[step],
        completed: isCompleted(step),
        isHidden: isHidden(step),
      };
    })
    .filter(step => {
      return !step.isHidden;
    });

  const getLink = (step: string) => {
    switch (step) {
      case OnboardingSteps.CONFIRM_YOUR_LEAD: {
        return Routes.Organisation.Users;
      }
      case OnboardingSteps.MANAGE_YOUR_REQUESTS: {
        return Routes.MyRequests;
      }
      case OnboardingSteps.QUESTIONNAIRE_SETUP: {
        return Routes.Setup.Root;
      }
      case OnboardingSteps.CONFIRM_YOUR_PARTICIPATION: {
        return Routes.Organisation.Details;
      }
      case OnboardingSteps.CHOOSE_FEE: {
        return hasInvoice ? Routes.Payment : Routes.Products.Root;
      }

      default: {
        return Routes.Home;
      }
    }
  };

  const hasRequests = !!data?.myRequests.requests?.length;

  const onStepClick = (step: UserStep) => {
    if (
      !(
        organisationTypeId === OrganisationTypeIdService.CITY() ||
        organisationTypeId === OrganisationTypeIdService.COMPANY() ||
        organisationTypeId === OrganisationTypeIdService.STATES_AND_REGIONS()
      )
    ) {
      enqueueSnackbar(t('toDoList.errors.accountSetup'), { variant: 'error' });
      return;
    }
    if (step.key === OnboardingSteps.CONFIRM_YOUR_LEAD) {
      if (moment(`${opensMonth} ${opensYear}`, 'MMMM YYYY').isAfter(moment())) {
        enqueueSnackbar(
          t('toDoList.errors.notAvailableUntilCycleOpens', { opensMonth: opensMonth, opensYear: opensYear }),
          { variant: 'info' },
        );
      } else {
        router.push(getLink(step.key));
        return;
      }
    }

    if (!hasRequests && step.key === OnboardingSteps.CHOOSE_FEE) {
      enqueueSnackbar(t('onboardingTile.actions.chooseFee.noRequests'), { variant: 'info' });
      return;
    }

    router.push(getLink(step.key));
  };

  return (
    <Stack>
      <Stepper
        sx={{ mt: 3 }}
        color="white"
      >
        {steps.map((step, index) => {
          return (
            <React.Fragment key={step.key}>
              <Step
                key={step.key}
                completed={step.completed}
                active={false}
                sx={{
                  '& .MuiStepLabel-root': {
                    cursor: 'pointer',
                  },
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => onStepClick(step)}
              >
                <Stack
                  key={`stack-${step.key}`}
                  direction="row"
                  spacing={1}
                  width={'100%'}
                  alignItems={'center'}
                >
                  <StepLabel icon={step.completed ? <CheckCircle color="success" /> : <CircleOutlined />}>
                    <Typography sx={{ color: step.completed ? 'grey.300' : 'white' }}>{step.name}</Typography>
                  </StepLabel>
                  {index > 0 && <ChevronRight />}
                </Stack>
              </Step>
              {index + 1 < Object.values(stepsDict).length && <StepConnector sx={{ minWidth: '30px' }} />}
            </React.Fragment>
          );
        })}
      </Stepper>
    </Stack>
  );
}
