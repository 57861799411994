import { ToDoListItemType } from '@/types/response';
import { Routes } from '@/utils/urls';

export const todoListItems: ToDoListItemType[] = [
  {
    title: 'listItemLabels.accessSetup',
    link: Routes.Setup.Root,
  },
  {
    title: 'listItemLabels.confirmIntention',
    link: Routes.Organisation.Details,
  },
  {
    title: 'listItemLabels.viewRequests',
    link: Routes.MyRequests,
  },
  {
    title: 'listItemLabels.viewGuidance',
    link: 'https://www.cdp.net/en/guidance_docs',
    newTab: true,
  },
];
export const preparePhaseTodoItems: ToDoListItemType[] = [
  {
    title: 'listItemLabels.yourRequests',
    link: Routes.MyRequests,
  },
  {
    title: 'listItemLabels.inviteMember',
    link: Routes.Organisation.Users,
  },
  {
    title: 'listItemLabels.reviewOrg',
    link: Routes.Organisation.Details,
  },
  {
    title: 'listItemLabels.reviewPersonalDetail',
    link: Routes.Account,
  },
];

export const insightsPhaseTodoItems: ToDoListItemType[] = [
  {
    title: 'listItemLabels.viewSubmittedResponse',
    link: Routes.Questionnaire.Response,
  },
  {
    title: 'listItemLabels.viewScoreBreakdown',
    link: Routes.DataAndInsights,
  },
  {
    title: 'listItemLabels.giveFeedback',
    link: 'https://customervoice.microsoft.com/Pages/ResponsePage.aspx?id=utjEsuvRe0-GR7qKgZ5zmeRp-RKrBkFEhuqskFASrJ9UNFhEQjdRWk9GUlpFWTFUS1cyVTdRRVdNTy4u',
    newTab: true,
  },
];
