// mui
import { Box, Grid, GridProps, Typography, alpha, styled } from '@mui/material';

const StatusBoxWrapper = styled(Grid, {
  shouldForwardProp: prop => prop !== 'color',
})<{ color?: string }>(({ color }) => {
  return {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    paddingBottom: '12px',
    background: 'transparent',
    '& .MuiSvgIcon-root': {
      color: color,
    },
  };
});

type Props = {
  keyLabel?: string;
  value?: string | number;
  icon?: React.ReactNode;
  color?: string;
  linkLabel?: string;
} & GridProps;

export default function StatusBox({ keyLabel, value, icon, color, linkLabel, ...rest }: Props) {
  return (
    <StatusBoxWrapper
      color={color}
      {...rest}
    >
      <Box
        sx={{
          pr: 1.5,
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography
          variant="caption"
          sx={{
            color: theme => theme.palette.text.secondary,
            pb: 1,
          }}
        >
          {keyLabel}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: theme => theme.palette.text.primary,
          }}
        >
          {value}
        </Typography>
        {linkLabel && (
          <Typography
            variant="caption"
            sx={{
              color: theme => `${alpha(theme.palette.secondary.main, 0.28)}`,
            }}
          >
            {linkLabel}
          </Typography>
        )}
      </Box>
    </StatusBoxWrapper>
  );
}
